import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import SampleForm from '../components/Form'
import pension from '../img/pension.svg'
// import swish from '../img/swish.png'
import swishw from '../img/swish-white.png'
import moneydialogue from '../img/money-dialogue.svg'
import empben from '../img/employer-benefits-icon.png'
import empbenWhite from '../img/employer-benefits-icon-white.png'

import retiree from '../img/beach-retirees.jpg'
import financefam from '../img/planning-retirees.jpg'
import couplefinance from '../img/plan-retirees.jpg'
import famfinance from '../img/family-finance.jpg'
import leafs from '../img/003-leaf.svg'
import swish from '../img/swish.png'

class ClientPage extends React.Component {
  // componentDidMount() {
  //   // 1. Create <script>Tag</script>
  //   const script = document.createElement('script')
  //   // 2. <script async>
  //   script.async = true
  //   // 3. <script src="src">
  //   script.src = 'https://assets.calendly.com/assets/external/widget.js'
  //   // 4. <script id="id">
  //   script.id = 'calendly'
  //   // 5. Insert script by getElementID
  //   document.getElementById('calendly').appendChild(script)
  // }
  render() {
    const siteTitle = 'Become A Client'
    const siteDescription = this.props.data.site.siteMetadata.description

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} description={siteDescription} />
        <div class="atf-client">
          <div class="container">
            <div class="col-md-12">
              <h1 class="atf-h text-center text-white">
                <strong>Documents to Gather</strong>
              </h1>
            </div>
          </div>
        </div>
        <div id="schedule" class="schedule2">
          <div class="container">
            <div class="row">
              <div class="col-md-6 sched">
                <div class="bg text-center">
                  <img alt="pension" src={pension} />
                </div>
                <h1 class="section-h text-center text-white">
                  <strong>
                    Schedule a 15 minute phone call to gain clarity on next
                    steps
                  </strong>
                </h1>
                <p class="atf-p text-white text-center">
                  Before committing your time or ours, this 15 minute phone call
                  will give us both a chance to make sure your situation matches
                  our experience. If we aren’t a good fit for each other we will
                  gladly introduce you to a firm who is better suited to your
                  needs.
                </p>
                <div class="swish text-center">
                  <img alt="swishw" src={swishw} />
                </div>
              </div>
              <div class="col-md-6 sched align-self-center text-center text-white">
                <Link
                  to="/appointment"
                  className="btn border text-white py-3 bttn"
                  style={{ fontSize: '25px' }}
                  target="_blank"
                >
                  <i class="fa fa-leaf" />
                  &nbsp; Schedule an Appointment
                </Link>
                {/* <div
                    class="calendly-inline-widget"
                    data-url="https://calendly.com/calendtest"
                  /> */}
              </div>
            </div>
          </div>
        </div>
        {/* <div class="intake-form">
          <div class="container-fluid">
            <div class="col-md-12">
              <div class="bg text-center">
                <img alt="empben" src={empben} />
              </div>

              <h1 class="section-h text-center">
                <strong>Documents to Gather</strong>
              </h1>
              <div className="row">
                <div class="hover-table-layout">
                  <div class="listing-item col-md-3">
                    <figure class="image">
                      <img src={retiree} alt="retiree" />
                      <figcaption>
                        <div class="caption">
                          <h1 class="small-h text-white">Retirement Income</h1>
                        </div>
                      </figcaption>
                    </figure>
                    <div class="listing">
                      <h4>Social Security Statement</h4>
                      <h4>Pension Statements</h4>
                      <h4>Annuity Statements</h4>
                    </div>
                  </div>
                  <div class="listing-item col-md-3">
                    <figure class="image">
                      <img src={financefam} alt="finance" />
                      <figcaption>
                        <div class="caption">
                          <h1 class="small-h text-white">Risk Management</h1>
                        </div>
                      </figcaption>
                    </figure>
                    <div class="listing">
                      <h4>Life Insurance Policy(s)</h4>
                      <h4>Property and Casualty Declaration Page</h4>
                      <h4>Trusts and Wills</h4>
                    </div>
                  </div>
                  <div class="listing-item col-md-3">
                    <figure class="image">
                      <img src={couplefinance} alt="couplefinance" />
                      <figcaption>
                        <div class="caption">
                          <h1 class="small-h text-white">Taxes</h1>
                        </div>
                      </figcaption>
                    </figure>
                    <div class="listing">
                      <h4>Last year’s Tax Reports</h4>
                      <h4>
                        Retirement Plan Contributions (could be found on last
                        statement)
                      </h4>
                    </div>
                  </div>
                  <div class="listing-item col-md-3">
                    <figure class="image">
                      <img src={famfinance} alt="famfinance" />
                      <figcaption>
                        <div class="caption">
                          <h1 class="small-h text-white">
                            Portfolio/Net Worth
                          </h1>
                        </div>
                      </figcaption>
                    </figure>
                    <div class="listing">
                      <h4>
                        Business entity organization documents (Ex: Articles of
                        Incorporation, etc)
                      </h4>
                      <h4>Business valuation summary (if applicable)</h4>
                      <h4>
                        Last statements for all investment accounts (Ex: 401(k),
                        403(b), 457, brokerage accounts, PERA, etc)
                      </h4>
                      <h4>Last mortgage statement</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="service-table">
          <div className="container-fluid">
            <div class="bg text-center">
              <img alt="empbenWhite" src={empbenWhite} />
            </div>

            <h1 class="section-h text-center text-white">
              <strong>Documents to Gather</strong>
            </h1>
            <div className="row">
              <div className="panel pricing-table">
                <div className="pricing-plan">
                  <h2 className="pricing-header" style={{ fontSize: '1.5rem' }}>
                    Investment information
                  </h2>
                  <div className="swish text-center">
                    <img alt="swish" src={swish} />
                  </div>
                  <ul className="pricing-features">
                    <li className="pricing-features-item">
                      ✓ Copy of most recent statement of holdings (Include
                      mutual funds, saving Accounts, IRAs, corporate savings and
                      thrift program, stock option, grants, vested pension
                      program amounts, etc.)
                    </li>
                  </ul>
                </div>

                <div className="pricing-plan">
                  <h2 className="pricing-header" style={{ fontSize: '1.5rem' }}>
                    Company Benefits Booklets or Employee Benefit Program
                    Description
                  </h2>
                  <div className="swish text-center">
                    <img alt="swish" src={swish} />
                  </div>
                  <ul className="pricing-features">
                    <li>
                      <div className="row">
                        <ul className="col-md-12">
                          <li className="pricing-features-item">
                             ✓ Group Health and Dental Insurance
                          </li>
                          <li className="pricing-features-item li-blue">
                            ✓ Group/Individual Disability Income Insurance
                          </li>
                          <li className="pricing-features-item li-blue">
                            ✓ Group Life Insurance
                          </li>
                          <li className="pricing-features-item li-blue">
                            ✓ Pension and Profit-Sharing Plans
                          </li>
                          <li className="pricing-features-item li-blue">
                            ✓ Stock Option Program
                          </li>
                          <li className="pricing-features-item li-blue">
                            ✓ Deferred Compensation Program
                          </li>
                        </ul>
                      </div>{' '}
                    </li>
                  </ul>
                </div>

                <div className="pricing-plan">
                  <h2 className="pricing-header" style={{ fontSize: '1.5rem' }}>
                    Insurance and Annuity Contracts
                  </h2>
                  <div className="swish text-center">
                    <img alt="swish" src={swish} />
                  </div>
                  <ul className="pricing-features">
                    <li>
                      <div className="row">
                        <ul className="col-md-12">
                          <li className="pricing-features-item">
                             ✓ Life Insurance
                          </li>
                          <li className="pricing-features-item li-blue">
                            ✓ Disability Income
                          </li>
                          <li className="pricing-features-item li-blue">
                            ✓ Long Term Care
                          </li>
                          <li className="pricing-features-item li-blue">
                            ✓ Professional Liability Insurance Policies
                          </li>
                          <li className="pricing-features-item li-blue">
                            ✓ Property and Casualty insurance (including
                            Umbrella liability/catastrophe policy)
                          </li>
                        </ul>
                      </div>{' '}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="panel pricing-table">
                <div className="pricing-plan">
                  <h2 className="pricing-header" style={{ fontSize: '1.5rem' }}>
                    Most Recent Paycheck Stub
                  </h2>
                  <div className="swish text-center">
                    <img alt="swish" src={swish} />
                  </div>
                </div>

                <div className="pricing-plan">
                  <h2 className="pricing-header" style={{ fontSize: '1.5rem' }}>
                    Wills and Trust Documents (including life insurance, trust,
                    and family Limited partnership documents)
                  </h2>
                  <div className="swish text-center">
                    <img alt="swish" src={swish} />
                  </div>
                </div>

                <div className="pricing-plan">
                  <h2 className="pricing-header" style={{ fontSize: '1.5rem' }}>
                    Business Organization and Agreements (if applicable)
                  </h2>
                  <div className="swish text-center">
                    <img alt="swish" src={swish} />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="panel pricing-table">
                <div className="pricing-plan">
                  <h2 className="pricing-header" style={{ fontSize: '1.5rem' }}>
                    Tax Return (including 1099s and k-1s)
                  </h2>
                  <div className="swish text-center">
                    <img alt="swish" src={swish} />
                  </div>
                </div>

                <div className="pricing-plan">
                  <h2 className="pricing-header" style={{ fontSize: '1.5rem' }}>
                    Real Estate Deeds (including out-of-state property)
                  </h2>
                  <div className="swish text-center">
                    <img alt="swish" src={swish} />
                  </div>
                </div>

                <div className="pricing-plan">
                  <h2 className="pricing-header" style={{ fontSize: '1.5rem' }}>
                    House of Securities (cash-flow worksheet)
                  </h2>
                  <div className="swish text-center">
                    <img alt="swish" src={swish} />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="panel pricing-table">
                <div className="pricing-plan">
                  <h2 className="pricing-header" style={{ fontSize: '1.5rem' }}>
                    Social Security Statements
                  </h2>
                  <div className="swish text-center">
                    <img alt="swish" src={swish} />
                  </div>
                </div>
                <div className="pricing-plan">
                  <h2 className="pricing-header" style={{ fontSize: '1.5rem' }}>
                    Last Mortgage Statement
                  </h2>
                  <div className="swish text-center">
                    <img alt="swish" src={swish} />
                  </div>
                </div>
                <div className="pricing-plan"></div>
              </div>
            </div>
          </div>
        </div>

        <div id="newsletter" class="cont">
          <div class="container">
            <div class="col-md-12">
              <div class="bg text-center">
                <img alt="money dialogue" src={moneydialogue} />
              </div>
              <h1 class="section-h text-center text-white">
                <strong>
                  Get advice from Journey Financial Services&nbsp;
                </strong>
              </h1>
              <p class="service-p text-white">
                <em>
                  You will automatically be subscribed to our quarterly
                  newsletter&nbsp;to receive e-mails&nbsp;filled with articles,
                  advice and financial guidance
                </em>
              </p>
            </div>
          </div>
          <div class="container">
            <SampleForm
              form_name="Newsletter Form"
              form_id="5df865b7676f4567eacd2532"
              form_classname="form-newsletter"
              class="row justify-content-center"
              method="post"
            >
              <div className="form-row">
                <div className="mb-3">
                  <div className="form-group">
                    <input
                      className="form-control c-form"
                      type="text"
                      name="Name"
                      id="full_name"
                    />
                    <label htmlFor="full_name">Enter Your Name</label>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="form-group">
                    <input
                      className="form-control c-form"
                      type="email"
                      name="Email Address"
                      id="email_address"
                    />
                    <label htmlFor="email_address">Email</label>
                  </div>
                  <small class="form-text text-danger">
                    Please enter a correct email address.
                  </small>
                </div>
                <div className="mb-3">
                  <div className="form-group">
                    <input
                      className="form-control c-form"
                      type="text"
                      name="MailingAddress"
                      id="mailing_address"
                    />
                    <label htmlFor="mailing_address">Mailing Address</label>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="form-group">
                    <input
                      className="form-control c-form"
                      type="text"
                      name="Phone"
                      id="phone"
                    />
                    <label htmlFor="phone">Phone</label>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="form-group">
                    <button
                      className="btn btn-primary bttn float-left c-button"
                      type="submit"
                    >
                      Subscribe
                    </button>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="form-group">
                    <div className="webriq-recaptcha" />
                  </div>
                </div>
              </div>
            </SampleForm>
          </div>
        </div>
        {/*<SampleForm
            form_name="Newsletter Form"
            form_id="5bcd42f86b63453b251972bc"
            form_classname="form-newsletter"
          >
          <div className="form-row">
          <div className="col-12 mb-3">
            <div className="form-group">
              <input
                className="form-control"
                type="email"
                name="Email Address"
                id="email_address"
              />
              <label htmlFor="email_address">
                Enter Your Email Address
              </label>
            </div>
            <small className="text-dark form-text mt-2">
                We will never share your email with anyone else.
              </small>
          </div>
          <div className="col-12 mb-3">
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                name="Name"
                id="full_name"
              />
              <label htmlFor="full_name">
                Enter Your Name
              </label>
            </div>
          </div> 
          <div className="col-12">
            
              <div className="webriq-recaptcha" />
            
          </div>

          <div className="col-12">
            <div className="form-group">
              <button
                className="btn btn-primary hvr-shadow"
                type="submit"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
          </SampleForm> */}
      </Layout>
    )
  }
}
export default ClientPage

export const ClientPageQuery = graphql`
  query ClientPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
